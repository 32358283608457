
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, List } from "vant";
import WaterfallLayout from "@/views/Mall/Details/waterfallLayout.vue";
import FooterLine from "@/views/Mall/MallPage/FooterLine.vue";
import { monitorEvent } from "@/utils/youMengMonitor";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [List.name]: List,
    WaterfallLayout,
    FooterLine,
  },
})
export default class VipMain extends Mixins(Mixin) {
  @Prop({
    type: Number,
    default: 1,
  })
  goodType!: number; //1商城里得，2 是会员中心 3.周边
  loading = false;
  finished = false;
  pages: number | undefined = 1;
  page = 0;
  size = 15;
  vipList: good.CommodityDto[] = [];
  id = 0; //查看当前周边的项目id
  type: "COMMODITY_TOUR" | "COMMODITY_PRODUCT" = "COMMODITY_TOUR"; //是巡演还是普通项目

  get memberLevelId(): string {
    return this.UserModule.memberLevelId || "0";
  }

  mounted(): void {
    if (this.goodType === 1) {
      this.getVipExclusive();
    } else if (this.goodType === 2) {
      this.getMineVip();
    } else if (this.goodType === 3) {
      this.id = this.$route.params.id ? Number(this.$route.params.id) : this.id;
      this.type =
        (this.$route.params.type as "COMMODITY_TOUR" | "COMMODITY_PRODUCT") ||
        this.type;
      this.getPeripheralGoods();
    }
  }

  goMallDetails(itemInfo: good.CommodityDto): void {
    if (this.$route.name === "vip-exclusive") {
      //埋点：会员专享商品列表页，点击一个会员商品
      monitorEvent(
        "VIPGoodsList_ClickGood",
        "点击一个会员商品",
        itemInfo.commodityTitle
      );
    } else if (this.$route.name == "membership") {
      monitorEvent("VIPCenter_ClickAGood", "点击会员专享一个商品"); // 埋点：会员中心，点击会员专享一个商品
    }
    this.$router.push(`/mall/details?commodityId=${itemInfo.commodityInfoId}`);
  }
  //下拉触底触发
  onLoad(): void {
    this.loading = true;
    if (this.goodType === 1) {
      this.getVipExclusive();
    } else if (this.goodType === 2) {
      this.getMineVip();
    } else if (this.goodType === 3) {
      this.getPeripheralGoods();
    }

    // 数据全部加载完成
    if (this.page >= Number(this.pages)) {
      this.finished = true;
      this.loading = false;
    }
  }
  /*
   * 商城会员专享
   */
  getVipExclusive(): void {
    this.page++;
    let data = {
      page: this.page,
      size: this.size,
    };
    this.$api.goodApi.commodity.findCommoditiesMember(
      data,
      ({ data }) => {
        this.loading = false;
        this.pages = data.pages;
        data.records?.forEach((value: good.CommodityDto) => {
          if (value.lowestMemberPrices) {
            let isMember = false;
            isMember = value.lowestMemberPrices.some((item) => {
              return item.memberGradeId === this.memberLevelId;
            });
            if (isMember) {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId === this.memberLevelId;
                }
              );
            } else {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId === "0";
                }
              );
            }
          }
        });
        this.vipList = this.vipList.concat(data.records!);
      },
      (error) => {
        this.loading = false;
        error && this.$toast(error);
      }
    );
  }

  /*
   * 会员中心会员专享
   */
  getMineVip(): void {
    this.page++;
    let data = {
      page: this.page,
      size: this.size,
    };
    this.$api.goodApi.commodity.findCommoditiesMemberMine(
      data,
      ({ data }) => {
        this.loading = false;
        this.pages = data.pages;
        data.records?.forEach((value: good.CommodityDto) => {
          if (value.lowestMemberPrices) {
            let isMember = false;
            isMember = value.lowestMemberPrices.some((item) => {
              return item.memberGradeId == this.memberLevelId;
            });
            if (isMember) {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId == this.memberLevelId;
                }
              );
            } else {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId == "0";
                }
              );
            }
          }
        });
        this.vipList = this.vipList.concat(data.records!);
      },
      (error) => {
        this.loading = false;
        error && this.$toast(error);
      }
    );
  }

  /*
   * 周边
   */
  getPeripheralGoods(): void {
    this.page++;
    let data = {
      page: this.page,
      size: this.size,
    };
    this.$api.goodApi.commodity.findCommoditiesPeriphery(
      `${this.id}`,
      this.type,
      data,
      ({ data }) => {
        this.loading = false;
        this.pages = data.pages;
        data.records?.forEach((value: good.CommodityDto) => {
          if (value.lowestMemberPrices) {
            let isMember = false;
            isMember = value.lowestMemberPrices.some((item) => {
              return item.memberGradeId === this.memberLevelId;
            });
            if (isMember) {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId === this.memberLevelId;
                }
              );
            } else {
              value.lowestMemberPrices = value.lowestMemberPrices.filter(
                (item) => {
                  return item.memberGradeId === "0";
                }
              );
            }
          }
        });
        this.vipList = this.vipList.concat(data.records!);
      },
      (error) => {
        this.loading = false;
        error && this.$toast(error);
      }
    );
  }
}
